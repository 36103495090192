module.exports = {
  methods: {
    CheckFallback() {
      var orientation = this.readDeviceOrientation();
      var checkWidth = orientation === "landscape" ? 850 : 600;

      if (window.innerWidth < checkWidth) {
        return true;
        
      }
      return false;
    },
    readDeviceOrientation() {
      let orientation = "desktop";
      switch (window.orientation) {
        case 0:
          // Portrait
          orientation = "portrait";
          break;

        case 180: orientation = "portrait";
          // Portrait (Upside-down)
          break;

        case - 90: orientation = "landscape";
          // Landscape (Clockwise)
          break;

        case 90: orientation = "landscape";
          // Landscape  (Counterclockwise)
          break;
      }

      return orientation;
    }
  }
}
