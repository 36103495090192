import SceneUtilsMixins from './SceneUtilsMixin';

export default {
  mixins: [SceneUtilsMixins],
  data() {
    return {
      pageContent: null,
    }
  },
  watch: {

  },
  methods: {
    OnUISectionMounted() {
      this.$store.commit("SetViewMode", this.Name);
      this.$store.commit("area/SetSunPosition", 0.5);
      this.$store.commit("area/SetCurrentSeason", "Summer");

      this.$store.commit("content/SetCurrentViewContent", this.pageContent);

      console.log("pageContent" , this.pageContent);

      this.SetCameraToUISection(this.pageContent);

      const nextRoom = this.$store.state.area.current==null  ? 'TinyCity' : this.$store.state.area.current == null ? 'default' : this.$store.state.area.current.defaultRoom
      this.$store.state.world.mainScene.xr.SceneController.SetActiveScene(
        nextRoom
      );
      this.$store.state.world.mainScene.xr.CSSSceneController.SetActiveScene(nextRoom);
    }
  },
  mounted() {

  },

}