import Utility from "../utils";

export default {
  data(){
    return {
      colors: {
        lightOrange : "#F9C697",
        lightBlue : "#31C5F4",
        orange : "#F38D2F"
      }
    }
  },
  computed: {
    GetSolarIndex: function () {
      return Utility.CalculateSolarIndex(this.$store.state.area.sunPosition);

    },
  },
  methods:{
    roundedRect(x, y, width, height, radius, tl = true,tr = true, br = true,bl = true, fill=true, stroke=false) {
      if (typeof radius === 'undefined') {
        radius = 5;
      }
      if (typeof radius === 'number') {
        radius = {
          tl: tl ? radius : 0, 
          tr: tr ? radius : 0, 
          br: br ? radius : 0, 
          bl: bl ? radius : 0
        };
      } else {
        var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
        for (var side in defaultRadius) {
          radius[side] = radius[side] || defaultRadius[side];
        }
      }
  
      this.c.beginPath();
      this.c.moveTo(x + radius.tl, y);
      
      this.c.lineTo(x + width - radius.tr, y);    
      this.c.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  
      this.c.lineTo(x + width, y + height - radius.br);
      this.c.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  
      this.c.lineTo(x + radius.bl, y + height);
      this.c.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  
      this.c.lineTo(x, y + radius.tl);
      this.c.quadraticCurveTo(x, y, x + radius.tl, y);
      
      this.c.closePath();
      
      if(fill){
        this.c.fill();
      }
  
      if(stroke){
        this.c.stroke();
      }
    }
  },
}