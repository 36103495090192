const LightFactory = {
  
  GetLightSetting(){
    return {
      "Season": "Summer",
      "DayLightSetting": {
        "SunIntensity": 3.0225745304153024,
        "AmbientIntensity": 0.6841288988454247,
        "HemiIntensity": 0.9598483542995002,
        "HemiLightFloorColor": "#ffffff",
        "SunColor": "#ffad83",
        "AmbientColor": "#ff4910",
        "HemiLightSkyColor": "#ffffff",
        "sky_color_top": "#d0ddff",
        "sky_color_center": "#b6dde5",
        "sky_color_bottom": "#43a8ff"
      },
      "SunsetLightSetting": {
        "SunIntensity": 2.9247318921049255,
        "AmbientIntensity": 0,
        "HemiIntensity": 0,
        "HemiLightFloorColor": "#ffffff",
        "SunColor": "#ff1e00",
        "AmbientColor": "#ffa367",
        "HemiLightSkyColor": "#ffffff",
        "sky_color_top": "#06c3ef",
        "sky_color_center": "#0163fc",
        "sky_color_bottom": "#e83a06"
      },
      "NightLightSetting": {
        "SunIntensity": 0,
        "AmbientIntensity": 0.13268998793727382,
        "HemiIntensity": 0.4435636739617439,
        "HemiLightFloorColor": "#2a2aef",
        "SunColor": "#38b7f9",
        "AmbientColor": "#7595c1",
        "HemiLightSkyColor": "#686868",
        "sky_color_top": "#ffffff",
        "sky_color_center": "#5154a3",
        "sky_color_bottom": "#000000"
      }
    }
  }
}

export default LightFactory;