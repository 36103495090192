module.exports = {
  computed:{
    
  },
  methods: {
    GetProgress() {
      const roomsToLoad = this.$store.state.area.areas[this.$route.params.area].rooms;
      let progress = 0;

      roomsToLoad.map(roomName =>{
        let roomProgress = 1;
        if(Object.prototype.hasOwnProperty.call(this.$store.state.world.roomProgress, roomName )){
          roomProgress = this.$store.state.world.roomProgress[roomName].progress;
        }
        progress += roomProgress
      })

      const globalProgress = progress / roomsToLoad.length;
    
      return globalProgress;
    },
    LoadInline(){
    
      const roomsToLoad = this.$store.state.area.areas[this.$route.params.area].rooms;
      if(Object.keys(this.$store.state.world.roomProgress).length == 0){
        this.$store.dispatch("world/LoadRooms", roomsToLoad);
      }else{
        const notLoadedRooms = roomsToLoad.filter(roomName =>{
          return this.$store.state.world.roomProgress[roomName].progress < 1 
        });
        
        this.$store.dispatch("world/LoadRooms", notLoadedRooms);
        
      }
      
      
    },
    
  }
}
