
export default {
    props: {
        state: {
          type: Number,
          default: 0,
          required: false,
        },
        activeColor: {
          type: String,
          default: "#ffffff",
          required: false,
        },
        defaultColor: {
          type: String,
          default: "#F38D2F",
          required: false,
        },
        disabledColor: {
          type: String,
          default: "#aaaaaa",
          required: false,
        },
      },
    computed: {
        GetState:function() {
          switch (this.state) {
            case -1:
              return this.disabledColor;
              break;
            case 0:
              return this.defaultColor;
              break;
            case 1:
              return this.activeColor;
              break;
          }
        },
      },
  }