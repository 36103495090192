import POIPositionFactory from "../Factory/POIPositionFactory";
import {ViewMode} from "../Enum/Enums"

export default {
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    NormalizeKeyNames(obj) {
      var clone = {};

      Object.keys(obj).map(keyName => {
        clone[this.capitalizeFirstLetter(keyName)] = obj[keyName];
      });

      return clone;

    },
    SetCameraToUISection(UISection) {    
      if (UISection == null) { return; }

      var clone = this.NormalizeKeyNames(UISection);
      
      console.log("SetCameraToUISection 1" , this.$store.state.area.current);
      var areaName = this.$store.state.area.current != null ? this.$store.state.area.current.name.toLowerCase() : this.$router.currentRoute.params.area;
      console.log("SetCameraToUISection 2" , areaName);
      var posData = POIPositionFactory(clone, false, areaName);

      console.log("SetCameraToUISection" , posData, clone, UISection);
      if(this.$store.state.area.current != null){

        console.log("SetCameraToUISection with area" , this.$store.state.area.current.cmsData);
        switch(this.$store.state.viewMode){
          case ViewMode.dashboard: 
            posData.position = this.$store.state.area.current.cmsData.Position;
            posData.target = this.$store.state.area.current.cmsData.Target;
          break;
          case ViewMode.explorer: 
            posData.position = this.$store.state.area.current.cmsData.PositionExplorer;
            posData.target = this.$store.state.area.current.cmsData.TargetExplorer;
          break;
          case ViewMode.configurator: 
            posData.position = this.$store.state.area.current.cmsData.PositionConfig;
            posData.target = this.$store.state.area.current.cmsData.TargetConfig;
          break;
          default: 
            posData.position = this.$store.state.area.current.cmsData.PositionConfig;
            posData.target = this.$store.state.area.current.cmsData.TargetConfig;
          break;
        }
      }

      if (clone.hasOwnProperty("PostProcessingSetting")) {
        posData.postProcessing.FOV_aperture = clone.PostProcessingSetting.FOV_aperture;
        posData.postProcessing.FOV_focus = clone.PostProcessingSetting.FOV_focus;
        posData.postProcessing.FOV_maxblur = clone.PostProcessingSetting.FOV_maxblur;
      }

      this.$store.state.world.mainScene.sceneController.SetPosition(posData);
    }
  }
}