import {Vector3} from 'three';

const POIPositionFactory = (poi, isSubTab, area)=>{


  console.log("%c POIPositionFactory" , "background: #333;color:#fff");
  console.log(poi, isSubTab, area);

  var o = {
    position : new Vector3(0,0,0),
    target : new Vector3(0,0,0),
    VR_Position: new Vector3(0,0,0),
    VR_Target :new Vector3(0,0,0),
    VR_Scale :new Vector3(1,1,1),
    postProcessing : {
      FOV_aperture: 0.0001395829743236257,
      FOV_focus: 11.252800275719457,
      FOV_maxblur: 0.004635533344821644,
    },
    SunAngle:  .9 ,
    SunPosition:  .5 
  }
  if(isSubTab){
    o.position = poi.subtabPosition != null ? poi.subtabPosition : o.position;
    o.target = poi.subtabTarget != null ? poi.subtabTarget : o.target;
    
    o.VR_Position = poi.subtabVR_Position != null ? poi.subtabVR_Position : o.VR_Position;
    o.VR_Target = poi.subtabVR_Target != null ? poi.subtabVR_Target : o.VR_Target;
    o.VR_Scale = poi.subtabVR_Scale != null ? poi.subtabVR_Scale : o.VR_Scale;
    
    
    o.postProcessing.FOV_aperture = poi.PostProcessingSetting.FOV_aperture != null ? poi.PostProcessingSetting.FOV_aperture : o.postProcessing.FOV_aperture;
    o.postProcessing.FOV_focus = poi.PostProcessingSetting.FOV_focus != null ? poi.PostProcessingSetting.FOV_focus : o.postProcessing.FOV_focus;
    o.postProcessing.FOV_maxblur = poi.PostProcessingSetting.FOV_maxblur != null ? poi.PostProcessingSetting.FOV_maxblur : o.postProcessing.FOV_maxblur;
    
  }else{
    o.position = poi.Position;
    o.target = poi.Target;
    
    if(area == "apartmentbuilding" && poi.VR_Position_ApartmentBuilding != undefined){
      
          o.VR_Position = poi.VR_Position_ApartmentBuilding != null ? poi.VR_Position_ApartmentBuilding : o.VR_Position;
          o.VR_Target = poi.VR_Target_ApartmentBuilding != null ? poi.VR_Target_ApartmentBuilding  : o.VR_Target;
          o.VR_Scale = poi.VR_Scale_ApartmentBuilding != null ? poi.VR_Scale_ApartmentBuilding : o.VR_Scale;

    }else{
      
      o.VR_Position = poi.VR_Position != null ? poi.VR_Position : o.VR_Position;
      o.VR_Target = poi.VR_Target != null ? poi.VR_Target  : o.VR_Target;
      o.VR_Scale = poi.VR_Scale != null ? poi.VR_Scale : o.VR_Scale;

    }

    //o.SunAngle = poi.SunAngle;
    o.SunPosition = poi.SunPosition;
  }


  return o;
}

export default POIPositionFactory;