import axios from 'axios';
import mainConfig from "../../main.config";


export default {
  methods : {
    Get(url){
      return axios.get(mainConfig.CMS_BASE_URL + url)
    },
    Put(url, payload){
      return axios({
        method : "PUT",
        url : url,
        data : payload,
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': `Bearer ${ this.$store.state.jwt }`
        }
      })
    },
    Delete(url){

      // return axios.delete(url,{
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${ this.$store.state.jwt }`
      //   }
      // });
      return axios({
        method : "DELETE",
        url : url,
        headers: {
          'Content-Type': 'application/json',
         // 'Authorization': `Bearer ${ this.$store.state.jwt }`
        }
      })
    },
    PostData(url, formData){
      return axios({
        method : "POST",
        url : url,
        data : formData,
        headers: {
          'Content-Type': `multipart/form-data;`,
          //'Authorization': `Bearer ${ this.$store.state.jwt }`
        }
      })
    },
    PreRender(text){

      if(text == null){return null;}
      
      text = text.replaceAll("](/uploads/", "]("+ mainConfig.CMS_BASE_URL  +"/uploads/");

      return text;
    },
  }
}